import {DisciplineData, DisciplineTitle, LeaderboardData, parseTitle, Title, UserResultData} from "./types";

export const DEFAULT_LOCALE = "cs";
export const CLIENT_ID = "103590";
export const FIREBASE_FUNCTIONS_URL = "https://us-central1-morosystems-morokilometry.cloudfunctions.net";
export const AUTHORIZE_URL = `${FIREBASE_FUNCTIONS_URL}/authorize`;
export const STRAVA_LOGIN_URL = `https://www.strava.com/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${AUTHORIZE_URL}&approval_prompt=force&scope=activity:read_all`;
export const EM_SPACE = " ";
export const EN_SPACE = " ";
export const THIN_SPACE = " ";
export const GET_URL = `${FIREBASE_FUNCTIONS_URL}/get`;
export const PRIMARY_COLOR = "#fcbf00";
export const PRIMARY_FONT_SIZE = "20px";
export const UNBREAKING_SPACE = " ";
export const DISCIPLINE_LOCAL_STORAGE_KEY = "discipline";
export const SCORE_THREASHOLD = 0.001;
export const MINUTES_IN_HOUR = 60;
export const KM_UNIT = "km";
export const MIN_UNIT = "min";
export const HOUR_UNIT = "hod";
export const METER_UNIT = "m";
export const CZK_UNIT = "Kč";

export const formatNumber = (value: number, fractionDigits: number = 0, minimumIntegerDigits: number = 1) => value.toLocaleString(DEFAULT_LOCALE, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    minimumIntegerDigits: minimumIntegerDigits,
})

export const sort = (leaderboardDataList: LeaderboardData[]) => leaderboardDataList
    .sort((a, b) => a.lastname.localeCompare(b.lastname))
    .sort((a, b) => a.firstname.localeCompare(b.firstname))
    .sort((a, b) => b.score - a.score);

export const convertGetUserDataListToDisciplineDataList = (data: UserResultData[]): DisciplineData[] => ([
    {
        title: DisciplineTitle.Ride,
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.rideKm,
            km: entry.rideKm,
            min: entry.rideMin,
            elevationGain: entry.rideElevationGain,
            eBike: entry.eBike,
        }))),
    },
    {
        title: DisciplineTitle.Run,
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.runKm,
            km: entry.runKm,
            min: entry.runMin,
            elevationGain: entry.runElevationGain,
            eBike: false,
        }))),
    },
    {
        title: DisciplineTitle.Walk,
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.walkKm,
            km: entry.walkKm,
            min: entry.walkMin,
            elevationGain: entry.walkElevationGain,
            eBike: false,
        }))),
    },
    {
        title: DisciplineTitle.Swim,
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.swimKm,
            km: entry.swimKm,
            min: entry.swimMin,
            elevationGain: 0,
            eBike: false,
        }))),
    },
    {
        title: DisciplineTitle.Fitness,
        unit: MIN_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.fitnessMin * 0.1,
            km: 0,
            min: entry.fitnessMin,
            elevationGain: 0,
            eBike: false,
        }))),
    },
    {
        title: DisciplineTitle.Inline,
        unit: KM_UNIT,
        leaderboard: sort(data.map((entry) => ({
            id: entry.id,
            addedTimestamp: entry.addedTimestamp,
            firstname: entry.firstname,
            lastname: entry.lastname,
            score: entry.inlineKm,
            km: entry.inlineKm,
            min: entry.inlineMin,
            elevationGain: entry.inlineElevationGain,
            eBike: false,
        }))),
    },
]);

export const getDefaultDisciplineTitle = (): Title => {
    const value = localStorage.getItem(DISCIPLINE_LOCAL_STORAGE_KEY);

    if (value === null) {
        return DisciplineTitle.Ride;
    }

    return parseTitle(value) ?? DisciplineTitle.Ride;
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const formatDateTime = (timestamp: number) => (new Date(timestamp)).toLocaleDateString(DEFAULT_LOCALE) + UNBREAKING_SPACE + (new Date(timestamp)).toLocaleTimeString(DEFAULT_LOCALE);

export const getDisciplineScoreSum = (selectedDiscipline: DisciplineData) => selectedDiscipline.leaderboard.map(({score}) => score).reduce((a, b) => a + b, 0);

export const getDisciplineKmSum = (selectedDiscipline: DisciplineData) => selectedDiscipline.leaderboard.map(({km}) => km).reduce((a, b) => a + b, 0);

export const getDisciplineMinSum = (selectedDiscipline: DisciplineData) => selectedDiscipline.leaderboard.map(({min}) => min).reduce((a, b) => a + b, 0);

export const getScoreInUnits = (unit: string, km: number, min: number): number =>
    unit === MIN_UNIT ? Math.floor(min / MINUTES_IN_HOUR) : Math.floor(km)

export const formatMinutes = (min: number): string => {
    return `${Math.floor(min / MINUTES_IN_HOUR)}${UNBREAKING_SPACE}${HOUR_UNIT}${UNBREAKING_SPACE}${formatNumber(min % MINUTES_IN_HOUR)}${UNBREAKING_SPACE}${MIN_UNIT}`;
}

export const formatMinutesWithSeconds = (min: number): string => {
    const minutes = Math.floor(min);
    const seconds = Math.round((min - minutes) * MINUTES_IN_HOUR);

    return `${formatNumber(minutes)}:${formatNumber(seconds, 0, 2)}`;
}

export const getScoreWithUnitsForTitle = (unit: string, km: number, min: number): string =>
    unit === MIN_UNIT
        ? formatMinutes(min)
        : `${formatNumber(km, 2)}${UNBREAKING_SPACE}${KM_UNIT}`

export const getTotalScore = (disciplineDataList: DisciplineData[]) => getTotalScoreForUnit(disciplineDataList, KM_UNIT) + (getTotalScoreForUnit(disciplineDataList, MIN_UNIT) / 10)

export const getTotalScoreForUnit = (disciplineDataList: DisciplineData[], unit: String) => disciplineDataList
    .filter((disciplineData) => disciplineData.unit === unit)
    .map((disciplineData) => getDisciplineScoreSum(disciplineData))
    .reduce((a, b) => a + b, 0);

export const hasClimberBadge = (member: LeaderboardData, minKm: number, minElevationPer10km: number): boolean =>
    member.km >= minKm && getAverageElevationPer10km(member) >= minElevationPer10km

export const getAverageElevationPer10kmFormatted = (member: LeaderboardData) =>
    formatNumber(getAverageElevationPer10km(member), 1)

export const getAverageElevationPer10km = (member: LeaderboardData): number =>
    member.elevationGain / member.km * 10

export const getAverageClimbPercentageFormatted = (member: LeaderboardData) =>
    formatNumber(getAverageClimbPercentage(member), 2)

export const getAverageClimbPercentage = (member: LeaderboardData): number =>
    (member.elevationGain / (member.km * 1000)) * 100

export const hasSpeedBadgeKmH = (member: LeaderboardData, minKm: number, minKmPerHour: number): boolean =>
    member.km >= minKm && getAverageSpeedKmH(member) >= minKmPerHour

export const getAverageSpeedKmHFormatted = (member: LeaderboardData) =>
    formatNumber(getAverageSpeedKmH(member), 1)

export const getAverageSpeedKmH = (member: LeaderboardData): number =>
    member.km / (member.min / 60)

export const getAverageSpeedMinKmFormatted = (member: LeaderboardData) =>
    `${formatMinutesWithSeconds(getAverageSpeedMinKm(member))}/${KM_UNIT}`

export const getAverageSpeedMinKm = (member: LeaderboardData): number =>
    member.min / member.km

export const getAverageSpeedMin100mFormatted = (member: LeaderboardData) =>
    `${formatMinutesWithSeconds(getAverageSpeedMin100m(member))}/100m`

export const getAverageSpeedMin100m = (member: LeaderboardData): number =>
    member.min / (member.km * 10)
