import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";

import { Router } from "./Router";
import "./index.css";

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <Router />
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
