import { FunctionComponent } from "react";

import {UNBREAKING_SPACE} from "../utils";
import { Spacer } from "./Spacer";
import {Tooltip} from "@mui/material";

export const ContactFooter: FunctionComponent = () => (
    <div>
        S láskou
        {UNBREAKING_SPACE}
        <a
            style={{ color: "black", textDecoration: "underline" }}
            href="https://pavelpo.cz/kontakty"
            target="_blank"
            rel="noreferrer"
        >
            Pavel
        </a>
        {UNBREAKING_SPACE}a{UNBREAKING_SPACE}
        <Tooltip title={`Honza B. + Martin H. + Ondra U. + Vojta Š.`} followCursor={true}>
            <span>
                MoroTým
            </span>
        </Tooltip>
        <Spacer />
    </div>
);
