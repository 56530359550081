import {FunctionComponent} from "react";
import {HomeLink} from ".";

import {ColoredTitle} from "./ColoredTitle";
import {Spacer} from "./Spacer";

export const Success: FunctionComponent = () => (
    <div className="content">
        <Spacer/>
        <ColoredTitle first="úspěšné" second="přidání"/>
        <Spacer/>
        <h3>Vítej v MoroKilometrech, tvé jméno a údaje se ukážou po příští aktualizaci dat.</h3>
        <Spacer/>
        <ul>
            <li style={{color: "red"}}>Vyčkej prosím na aktualizaci dat ze Stravy, po které uvidíš tvá data za tento měsíc.</li>
            <li>Aktualizace dat ze Stravy probíhá každou celou hodinu v čase od 8 hod. ráno do půlnoci.</li>
            <li>Nahrání dat za minulý měsíc probíhá na konci každého dne.</li>
        </ul>
        <Spacer/>
        <HomeLink/>
    </div>
);
