import { FunctionComponent } from "react";

import { EM_SPACE, formatDateTime } from "../../utils";

export type UpdateFooterProps = {
    timestamp: number,
    onOpenDescription: () => void,
}

export const UpdateFooter: FunctionComponent<UpdateFooterProps> = ({ timestamp, onOpenDescription }) => (
    <span style={{ opacity: 0.3 }}>
        Aktualizováno {formatDateTime(timestamp)}
        {EM_SPACE}
        <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={onOpenDescription}>
            Proč?
        </span>
    </span>
);
