import { FunctionComponent } from "react";

import { PRIMARY_COLOR } from "../../utils";

export const HomeLink: FunctionComponent = () => (
    <a
        href="/"
        style={{ color: PRIMARY_COLOR }}
    >
        Domů
    </a>
);
