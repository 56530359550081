import { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export type InfoDialogProps = {
    isOpened: boolean,
    onClose: () => void,
}

export const InfoDialog: FunctionComponent<InfoDialogProps> = ({ isOpened, onClose }) => (
    <Dialog
        open={isOpened}
        onClose={onClose}
    >
        <DialogTitle >
            O co jde?
        </DialogTitle>
        <DialogContent>
            <DialogContentText color="#000">
                Máme rádi sport, data a technologie. Již několik let soutěžíme v celofiremní výzvě, kdo nasbírá během roku nejvíc kilometrů/minut.
                Evidujeme, kolik naběháme, uplaveme, ujedeme na kole, na bruslích nebo kolik minu strávíme jinou sportovní aktivitou.
                Na konci roku nasportované kilometry a minuty z celé firmy sečteme, přetavíme na Kč a výslednou sumou obdarujeme někoho v našem okolí.
                <br/>
                <br/>
                Morokilometry evidujeme v těchto kategoriích:
                <br/>
                <br/>
                <ul>
                    <li>Kolo | 1 km ~ 1 Kč | Strava: Ride, VirtualRide, Handcycle</li>
                    <li>Běh | 1 km ~ 1 Kč | Strava: Run, VirtualRun</li>
                    <li>Chůze | 1 km ~ 1 Kč | Strava: Walk, Hike</li>
                    <li>Plavání | 1 km ~ 1 Kč | Strava: Swim</li>
                    <li>Brusle | 1 km ~ 1 Kč | Strava: InlineSkate</li>
                    <li>Fitness | 10 min ~ 1 Kč | Strava: Workout, Yoga, WeightTraining</li>
                </ul>
                <br/>
                Morokilometry běží každý rok od začátku února do konce listopadu.
                <br/>
                <br/>
                <a
                    href="https://www.morosystems.cz/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                >
                    www.morosystems.cz
                </a>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
);
