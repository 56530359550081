import { FunctionComponent } from "react";

export const StravaLogo: FunctionComponent = () => (
    <a
        href="https://www.strava.com/"
        target="_blank"
        rel="noreferrer"
    >
        <img className="strava-logo" src="/strava_logo.png?v2" alt="Strava logo" />
    </a>
);
