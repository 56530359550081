import { FunctionComponent } from "react";

import { PRIMARY_COLOR } from "../utils";

export type ColoredTitleProps = {
    first: string,
    second: string,
    third?: string,
}

export const ColoredTitle: FunctionComponent<ColoredTitleProps> = ({ first, second, third }) => (
    <h2>
        <span style={{ color: PRIMARY_COLOR }}>{first}</span>
        {second}
        {third && (
            <span style={{ opacity: 0.3 }}>{third}</span>
        )}
    </h2>
);
