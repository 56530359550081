import { FunctionComponent } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { App } from "./App";
import { Error, Success } from "./components";

export const Router: FunctionComponent = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/problem">
                <Error />
            </Route>
            <Route exact={true} path="/success/added">
                <Success />
            </Route>
            <Route path="/">
                <App />
            </Route>
        </Switch>
    </BrowserRouter>
);
