import { createTheme, ThemeProvider } from "@mui/material";

import { PRIMARY_COLOR } from "./utils";
import { Content } from "./components";
import "./App.css";

const theme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR,
        },
        secondary: {
            main: "#555",
        },
    },
});

export const App = () => (
    <ThemeProvider theme={theme}>
        <Content />
    </ThemeProvider>
);
