import { FunctionComponent } from "react";

import { ColoredTitle } from "./ColoredTitle";
import { Spacer } from "./Spacer";

export const Loading: FunctionComponent = () => (
    <div className="content">
        <Spacer />
        <Spacer />
        <ColoredTitle first="načítám" second="sportovce" />
    </div>
);
