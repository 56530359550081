import { FunctionComponent } from "react";

export const IconButtonHitArea: FunctionComponent = () => (
    <div style={{
        width: 80,
        height: 80,
        position: "absolute",
        zIndex: 1,
    }} />
);
